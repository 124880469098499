<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :value="date"
        :label="$t('profile.info.dateBirth')"
        :disabled="disabled"
        prepend-icon="tl-cake"
        readonly
      />
    </template>
    <v-date-picker
      ref="picker"
      :value="date"
      :locale="locale"
      :max="new Date().toISOString().substr(0, 10)"
      min="1920-01-01"
      color="primary"
      @change="save"
    />
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    date: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    ...mapState('i18n', ['locale']),
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    save(newDate) {
      this.$emit('input', newDate)
      this.$refs.menu.save(newDate)
    },
  },
}
</script>
