<template>
  <v-card v-if="gns.gym" class="mx-auto" outlined>
    <v-list-item class="grey lighten-2">
      <v-list-item-avatar>
        <v-img :src="gns.gym.srcIcon" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="body-1">{{ gns.gym.name }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon @click="$emit('remove')">
          <v-icon>tl-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card-text>
      <v-checkbox
        v-if="gns.gym.nr_of_routes"
        :input-value="gns.route"
        :label="$t('profile.notifications.newRoutes')"
        color="primary"
        class="mt-0"
        hide-details
        @change="updateProp('route', !!$event)"
      />
      <v-checkbox
        v-if="gns.gym.nr_of_boulders"
        :input-value="gns.boulder"
        :label="$t('profile.notifications.newBoulders')"
        color="primary"
        class="mt-0"
        hide-details
        @change="updateProp('boulder', !!$event)"
      />
      <v-checkbox
        :input-value="gns.competitions"
        :label="$t('profile.notifications.competitions')"
        color="primary"
        class="mt-0"
        hide-details
        @change="updateProp('competitions', !!$event)"
      />
      <v-checkbox
        :input-value="gns.news"
        :label="$t('profile.notifications.news')"
        color="primary"
        class="mt-0"
        hide-details
        @change="updateProp('news', !!$event)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    gns: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
  },
  mounted() {
    this.$store.dispatch('autosave/addRecordsToSave', [this.gns])
  },
  destroyed() {
    this.$store.dispatch('autosave/removeRecordsToSave', [this.gns])
  },
  methods: {
    updateProp(prop, newVal) {
      this.gns.$update({ [prop]: newVal })
    },
  },
}
</script>
