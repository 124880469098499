<template>
  <v-container grid-list-lg>
    <v-layout row wrap>
      <v-flex xs12 md7 lg6 offset-lg1>
        <v-card class="pt2">
          <v-card-title>
            <v-layout column align-center>
              <tl-profile-img :disabled="disabled" />
              <h2>{{ fullName }}</h2>
              <p v-if="gym">{{ $t('sidenav.atGym', { gymName: gym.name }) }}</p>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-layout column>
              <v-flex>
                <v-text-field
                  :value="user.first_name"
                  :label="$t('profile.info.firstName')"
                  :disabled="disabled"
                  maxlength="20"
                  hide-details
                  @input="setUserProp('first_name', $event)"
                />
              </v-flex>
              <v-flex>
                <v-text-field
                  :value="user.last_name"
                  :label="$t('profile.info.lastName')"
                  :disabled="disabled"
                  maxlength="20"
                  hide-details
                  @input="setUserProp('last_name', $event)"
                />
              </v-flex>

              <v-flex>
                <v-radio-group :value="user.gender" :disabled="disabled" mandatory row hide-details @change="setGender">
                  <v-radio :label="$t('profile.info.male')" value="male" color="primary" />
                  <v-radio :label="$t('profile.info.female')" value="female" color="primary" />
                  <v-radio :label="$t('profile.info.other')" value="other" color="primary" />
                </v-radio-group>
              </v-flex>

              <v-flex>
                <v-checkbox
                  :input-value="user.anonymous"
                  :label="`${$t('profile.info.anonymous')} (${$t('profile.preferences.anonymousSuffix')})`"
                  :disabled="disabled"
                  color="primary"
                  hide-details
                  single-line
                  @click.stop="toggleAnonymous()"
                />
              </v-flex>

              <v-flex>
                <tl-country-select :value="user.country" :disabled="disabled" @input="setUserProp('country', $event)" />
              </v-flex>

              <v-flex>
                <v-text-field
                  :value="user.city"
                  :label="$t('profile.info.city')"
                  :disabled="disabled"
                  maxlength="20"
                  hide-details
                  @input="setUserProp('city', $event)"
                />
              </v-flex>

              <v-flex>
                <v-layout row>
                  <v-flex>
                    <tl-date-birth
                      :date="user.date_birth"
                      :disabled="disabled"
                      @input="setUserProp('date_birth', $event)"
                    />
                  </v-flex>
                  <v-flex>
                    <v-checkbox
                      :input-value="user.date_birth_hidden"
                      :label="$t('profile.info.hideDateBirth')"
                      :disabled="disabled"
                      color="primary"
                      @click.stop="setUserProp('date_birth_hidden', !user.date_birth_hidden)"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex>
                <v-text-field
                  type="number"
                  :value="user.length"
                  :label="$t('profile.info.length')"
                  :disabled="disabled"
                  min="0"
                  max="250"
                  hide-details
                  @input="setUserProp('length', $event)"
                />
              </v-flex>

              <v-flex>
                <v-text-field
                  type="number"
                  :value="user.weight"
                  :label="$t('profile.info.weight')"
                  :disabled="disabled"
                  min="0"
                  max="250"
                  hide-details
                  @input="setUserProp('weight', $event)"
                />
              </v-flex>

              <v-flex>
                <v-text-field
                  v-if="!disabled"
                  :value="user.email"
                  :label="$t('profile.info.email')"
                  append-outer-icon="tl-edit"
                  hide-details
                  readonly
                  @click="editEmail"
                  @click:append-outer="editEmail"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-flex xs12 md5 lg4>
        <v-layout column>
          <v-flex>
            <v-card>
              <v-card-title v-t="'profile.password.changePwd'" />
              <v-card-text>
                <v-form ref="form" v-model="updatePwdValid" @submit="updatePassword">
                  <v-layout column>
                    <v-flex>
                      <v-text-field
                        v-model="pwd"
                        :append-icon="showPwd ? 'tl-visibility-off' : 'tl-visibility'"
                        :rules="rulesPwd"
                        :type="showPwd ? 'text' : 'password'"
                        :label="$t('profile.password.newPwd')"
                        counter
                        required
                        prepend-icon="tl-lock"
                        @click:append="showPwd = !showPwd"
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="pwdConfirm"
                        :append-icon="showPwdConfirm ? 'tl-visibility-off' : 'tl-visibility'"
                        :rules="rulesPwdConfirm"
                        :type="showPwdConfirm ? 'text' : 'password'"
                        :label="$t('profile.password.confirmNewPwd')"
                        required
                        prepend-icon="tl-lock"
                        @click:append="showPwdConfirm = !showPwdConfirm"
                      />
                    </v-flex>
                    <v-flex>
                      <v-layout row justify-end>
                        <v-btn text type="submit" :loading="updatingPwd" :disabled="!updatePwdValid">
                          {{ $t('profile.password.changePwd') }}
                        </v-btn>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex>
            <v-card>
              <v-card-title v-t="'profile.preferences.preferences'" />
              <v-card-text>
                <v-layout column>
                  <v-flex>
                    <v-checkbox
                      :input-value="user.newsletters"
                      :label="$t('profile.info.newsletters')"
                      :disabled="disabled"
                      color="primary"
                      hide-details
                      single-line
                      @click.stop="setUserProp('newsletters', !user.newsletters)"
                    />
                  </v-flex>

                  <v-flex>
                    <tl-locale-selector :disabled="disabled" />
                  </v-flex>

                  <v-list two-line v-if="false">
                    <v-divider />
                    <v-subheader>Grading systems</v-subheader>

                    <v-list-item @click="showGradesChartDialog()">
                      <v-list-item-content>Show comparison chart</v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>tl-arrow-forward</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon class="grey lighten-1 white--text">tl-check-route</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <tl-grade-system-selector
                          :value="user.grading_system_routes"
                          climb-type="route"
                          prepend-icon
                          @input="setGradingSystem('route', $event)"
                        />
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon class="grey lighten-1 white--text">tl-check-boulder</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <tl-grade-system-selector
                          :value="user.grading_system_boulders"
                          climb-type="boulder"
                          prepend-icon
                          @input="setGradingSystem('boulder', $event)"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex>
            <tl-notific-prefs :disabled="disabled" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import saveButtonMixin from '@/components/shared/save-button.mixin'
import * as rules from '@/services/input-rules'
import tlProfileImg from './tl-profile-img'
import tlDateBirth from './tl-date-birth'
import tlCountrySelect from './tl-country-select'
import tlLocaleSelector from '@/components/shared/tl-locale-selector'
import tlGradeSystemSelector from '@/services/grades/tl-grade-system-selector'
import tlGradesChartDialogMixin from '@/components/shared/tl-grades-chart/tl-grades-chart.dialog.mixin'
import tlNotificPrefs from './tl-notific-prefs'

export default {
  mixins: [toolbarMixin, saveButtonMixin, tlGradesChartDialogMixin],
  components: {
    tlProfileImg,
    tlDateBirth,
    tlCountrySelect,
    tlLocaleSelector,
    tlGradeSystemSelector,
    tlNotificPrefs,
  },
  head() {
    return {
      title: this.$t('profile.title'),
      meta: [{ vmid: 'description', name: 'description', content: this.$t('profile.description') }],
    }
  },
  data: () => ({
    pwd: '',
    pwdConfirm: '',
    showPwd: false,
    showPwdConfirm: false,
    updatePwdValid: false,
    updatingPwd: false,
    rules,
  }),
  computed: {
    ...mapState(['user', 'gym']),
    tlToolbarTitle() {
      return this.$t('sidenav.profile')
    },
    tlToolbarButtons() {
      return [this.tlSaveBtn]
    },
    fullName() {
      return this.user.fullName || this.$t('sidenav.withoutName')
    },
    disabled() {
      return !!this.user.guest
    },
    rulesPwd() {
      return [this.rules.required, this.rules.pwdMinLength]
    },
    rulesPwdConfirm() {
      return [this.rules.required, this.rules.pwdMatch(this.pwd)]
    },
  },
  created() {
    if (!this.disabled) this.$store.dispatch('autosave/setRecordsToSave', [this.user])
  },
  methods: {
    ...mapActions('toast', { toastInfo: 'info' }),
    ...mapActions('dialog', ['prompt']),
    setUserProp(prop, val) {
      this.user.$update({ [prop]: val })
    },
    setGender(newVal) {
      if (newVal === 'other' && !this.user.anonymous) {
        this.setUserProp('anonymous', true)
        this.toastInfo(this.$t('profile.preferences.anonymousSetToast'))
      }
      this.setUserProp('gender', newVal)
    },
    toggleAnonymous() {
      if (this.user.anonymous && this.user.gender === 'other') {
        this.setUserProp('gender', 'male')
        this.toastInfo(this.$t('profile.preferences.genderSetToast'))
      }
      this.setUserProp('anonymous', !this.user.anonymous)
    },
    editEmail() {
      this.prompt({
        title: this.$t('profile.info.changeMail'),
        text: this.$t('profile.info.enterNewMail'),
        type: 'email',
        rules: [this.rules.required, this.rules.email],
        label: this.$t('profile.info.newEmail'),
        ok: this.$t('generic.save'),
        onSave: newMail => {
          this.$store.dispatch('auth/changeEmail', { userId: this.user.id, newMail })
          this.toastInfo(this.$t('auth.msgSentConfirmNew'))
          this.$router.push({ name: 'auth.logout' })
        },
      })
    },
    updatePassword(e) {
      e.preventDefault()
      if (!this.$refs.form.validate()) return
      this.updatingPwd = true
      this.$store
        .dispatch('auth/updatePassword', { userId: this.user.id, pwd: this.pwd, pwdConfirm: this.pwdConfirm })
        .then(this.$refs.form.reset)
        .finally(() => {
          this.updatingPwd = false
        })
    },
    setGradingSystem(type, systemName) {
      this.setUserProp(`grading_system_${type}s`, systemName)
      this.$store.dispatch('updateGradeSystems')
    },
  },
}
</script>
