<template>
  <v-flex class="tl-profile-img ma-4">
    <v-img
      v-if="user && user.avatar && user.avatar != avatarError"
      :src="user.avatar"
      :alt="$t('sidenav.profilePic')"
      class="tl-profile-img__img"
      @error="avatarError = user.avatar + ''"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey darken-3"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-icon v-else class="tl-profile-img__icon grey lighten-2 white--text" :size="140" v-text="'tl-person'" />
    <v-menu v-if="!disabled" bottom left offset-y>
      <template v-slot:activator="{ on }">
        <v-btn fab absolute bottom right color="primary" class="tl-profile-img__fab" v-on="on">
          <v-icon>tl-edit</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="openImgUploader()">
          <v-list-item-title v-t="'profile.info.upload'" />
        </v-list-item>
        <v-list-item @click="setAvatarOmniauth">
          <v-list-item-title v-t="'profile.info.socialMedia'" />
        </v-list-item>
        <v-list-item @click="setAvatarDisabled">
          <v-list-item-title v-t="'generic.none'" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-flex>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    avatarError: null,
  }),
  computed: {
    ...mapState(['user']),
  },
  methods: {
    async openImgUploader() {
      const component = () => import('@/components/shared/tl-img-upload.vue')
      const props = {
        initialImg: this.user.avatar_uploaded,
        title: this.$t('profile.info.changePicture'),
        maxWidth: 400,
        width: 300,
        height: 300,
        rounded: true,
        folder1: 'avatars',
      }
      let urlUploaded = await this.$store.dispatch('dialog/open', { component, props })
      if (urlUploaded) this.setAvatarUploaded(urlUploaded)
    },
    setAvatarUploaded(urlUploaded) {
      this.user.$update({
        avatar_source: 'uploaded',
        avatar_uploaded: urlUploaded,
        avatar: urlUploaded,
      })
    },
    setAvatarOmniauth() {
      this.user.$update({
        avatar_source: 'omniauth',
        avatar: this.user.avatar_omniauth,
      })
    },
    setAvatarDisabled() {
      this.user.$update({
        avatar_source: 'disabled',
        avatar: null,
      })
    },
  },
}
</script>

<style lang="sass">
.tl-profile-img
  position: relative
  &__img
    border-radius: 100%
    width: 250px
  &__icon
    border-radius: 100%
    padding: 20px
  & &__fab
    bottom: 0 !important
    z-index: 1
</style>
