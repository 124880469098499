import countries from '@/services/countries'

// Nests gyms into array of countries
export function gymsByCountry(gyms) {
  let groups = new Array()
  gyms.slice().forEach(gym => {
    let group = groups.find(g => g.countryCode == gym.country)
    if (!group) {
      group = { countryCode: gym.country, country: countries[gym.country], gyms: [] }
      groups.push(group)
    }
    group.gyms.push(gym)
    group.gyms.sort((a, b) => a.name.localeCompare(b.name))
  })
  return groups.sort((a, b) => a.country.localeCompare(b.country))
}
