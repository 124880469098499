<template>
  <v-card :loading="loading">
    <v-card-title v-t="'profile.notifications.notifications'" />

    <div class="pa-4 pt-0">
      <span class="subtitle-1 font-weight-bold" v-t="'profile.notifications.channels'" />
      <v-switch
        :input-value="user.notify_push"
        :disabled="disabled"
        color="primary"
        hide-details
        @change="updateUserProp('notify_push', !!$event)"
      >
        <template v-slot:label>
          {{ $t('profile.notifications.push') }}
          <span class="caption text-no-wrap"> ({{ $t('generic.comingSoon') }})</span>
        </template>
      </v-switch>
      <v-switch
        :input-value="user.notify_mail"
        :label="$t('profile.info.email')"
        :disabled="disabled"
        color="primary"
        @change="updateUserProp('notify_mail', !!$event)"
      />

      <span class="subtitle-1 font-weight-bold" v-t="'gyms.gyms'" />
      <v-select
        v-model="selectedGymIds"
        :items="groupedGyms"
        :disabled="disabled"
        :placeholder="$t('profile.notifications.selectGym')"
        multiple
        item-text="name"
        item-value="id"
        @focus="fetchGyms"
      >
        <template v-slot:prepend-item>
          <v-list-item>
            <v-text-field v-model="gymSearch" :label="$t('generic.search')" clearable />
          </v-list-item>
          <v-divider />
        </template>

        <template v-slot:selection="{ item, index }">
          <span v-if="index == 0" class="grey--text">
            {{ $t('profile.notifications.gymsSelected', { number: selectedGymIds.length }) }}
          </span>
        </template>
      </v-select>

      <div v-if="!disabled">
        <tl-gns-editor
          v-for="gns in user.gym_notify_settings"
          :key="gns.id"
          :gns="gns"
          class="mb-3"
          @remove="deselectGym(gns.gym_id)"
        />
      </div>

      <span
        v-if="anyNotificationsOfType()"
        class="subtitle-1 font-weight-bold"
        v-t="'profile.notifications.restrictRange'"
      />

      <div v-if="anyNotificationsOfType('route')">
        {{ $t('profile.notifications.onlyRoutesBetween') }}
        <tl-grade-range-slider v-model="notifyGradesRoute" type="route" class="px-6 pt-2" />
      </div>

      <div v-if="anyNotificationsOfType('boulder')">
        {{ $t('profile.notifications.onlybouldersBetween') }}
        <tl-grade-range-slider v-model="notifyGradesBoulder" type="boulder" class="px-6 pt-2" />
      </div>
      <p class="caption grey--text" v-t="'profile.notifications.noGuarantee'" />
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Gym from '@/models/Gym'
import GymNotifySetting from '@/models/GymNotifySetting'
import tlGnsEditor from './tl-gns-editor'
import tlGradeRangeSlider from '@/components/shared/tl-grade-sliders/tl-grade-range-slider'
import { gymsByCountry } from '@/components/gyms/utils'

export default {
  components: {
    tlGnsEditor,
    tlGradeRangeSlider,
  },
  props: {
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    gymSearch: '',
    gymsLoaded: false,
  }),
  computed: {
    ...mapState(['user']),
    gyms() {
      if (!this.gymsLoaded) return []
      return Gym.all().filter(g => g.live || g.live == undefined)
    },
    filteredGyms() {
      if (!this.gymSearch) return this.gyms
      return this.gyms.filter(gym => gym.name.toLowerCase().indexOf(this.gymSearch.toLowerCase()) > -1)
    },
    groupedGyms() {
      return gymsByCountry(this.filteredGyms).reduce((groupedGyms, group) => {
        groupedGyms.push({ header: group.country })
        return groupedGyms.concat(group.gyms)
      }, [])
    },
    selectedGymIds: {
      get() {
        return this.user.gym_notify_settings.map(gns => gns.gym_id)
      },
      set(newGymIds) {
        // Destroy deselected:
        this.user.gym_notify_settings.forEach(gns => {
          if (!newGymIds.includes(gns.gym_id)) {
            gns.$apiDestroy({ eagerEject: true })
          }
        })
        // Create selected
        let presentGymIds = this.user.gym_notify_settings.map(gns => gns.gym_id)
        newGymIds.forEach(gymId => {
          if (!presentGymIds.includes(gymId)) {
            GymNotifySetting.$apiCreate( // eslint-disable-line
              { user_id: this.user.id, gym_id: gymId },
              { eagerInject: true }
            )
          }
        })
      },
    },
    notifyGradesRoute: {
      get() {
        return [this.user.notify_new_route_min, this.user.notify_new_route_max]
      },
      set(newVal) {
        this.user.$update({
          notify_new_route_min: newVal[0],
          notify_new_route_max: newVal[1],
        })
      },
    },
    notifyGradesBoulder: {
      get() {
        return [this.user.notify_new_boulder_min, this.user.notify_new_boulder_max]
      },
      set(newVal) {
        this.user.$update({
          notify_new_boulder_min: newVal[0],
          notify_new_boulder_max: newVal[1],
        })
      },
    },
  },
  created() {
    this.fetchGns()
  },
  methods: {
    updateUserProp(prop, val) {
      this.user.$update({ [prop]: val })
    },
    async fetchGns() {
      if (this.disabled) return
      this.loading = true
      GymNotifySetting.$apiAll({ params: { user_id: this.user.id } }).finally(() => (this.loading = false))
    },
    async fetchGyms() {
      await Gym.$apiAll()
      this.gymsLoaded = true
    },
    deselectGym(gym_id) {
      let gns = this.user.gym_notify_settings.find(gns => gns.gym_id == gym_id)
      if (gns) gns.$apiDestroy({ eagerEject: true })
    },
    anyNotificationsOfType(type) {
      return this.user.gym_notify_settings.some(gns => {
        return type ? gns[type] : gns['route'] || gns['boulder']
      })
    },
  },
}
</script>
